import React, { useState } from "react";
import img1 from "../../assets/image/media/support/png/SupportProactive.png";
import img2 from "../../assets/image/media/support/png/SupportIndepth.png";
import img3 from "../../assets/image/media/support/png/SupportFuture.png";
import TabNavItem from "../design/TabNavItem";
import TabContent from "../design/TabContent";

const Tabs = ({ className, ...rest }) => {
  const [activeTab, setActiveTab] = useState("tab2");

  return (
    <div className={className} {...rest}>
      <div className="container pt-25 pt-lg-31 pb-13 pb-lg-18">
        <div className="row justify-content-center">
          <div className="col-xl-9 col-lg-8 col-md-8 col-xs-8">
            <div
              className="text-center mb-7 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <h2 className="font-size-10 letter-spacing-n83">
                Here’s how it works for you:
              </h2>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          {/* Hero Content */}
          <div className="col-xl-6 col-lg-6 col-md-6 col-xs-8 order-2 order-lg-1">
            <div
              className="mt-8 mt-lg-0 border rounded-20 pl-10 pr-10 pt-8 mb-8 box-shadow"
              data-aos="fade-right"
              data-aos-delay={500}
            >
              <TabNavItem
                id="tab1"
                title="Proactive advice"
                desc="We don’t wait for you to pick up the phone—we’ll proactively contact you with useful insights on your product."
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
            <div
              className="mt-8 mt-lg-0 border rounded-20 pl-10 pr-10 pt-8 mb-8 box-shadow"
              data-aos="fade-right"
              data-aos-delay={500}
            >
              <TabNavItem
                id="tab2"
                title="In-depth knowledge"
                desc="Get support from developers who know your product in detail—not call-center agents reading a script."
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
            <div
              className="mt-8 mt-lg-0 border rounded-20 pl-10 pr-10 pt-8 mb-8 box-shadow"
              data-aos="fade-right"
              data-aos-delay={500}
            >
              <TabNavItem
                id="tab3"
                title="Future focus"
                desc="We’ll advise on product updates to keep pace with market trends—and stay ahead of the competition."
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
          </div>
          {/* End Hero Content */}
          {/* Hero Image */}
          <div className="col-xl-6 col-lg-6 col-md-6 col-xs-8 order-1 order-md-2">
            <div className="pt-lg-3" data-aos="flip-right" data-aos-delay={900}>
              <div className="l3-content-image-5 mt-5 mt-lg-0">
                <TabContent id="tab1" activeTab={activeTab}>
                  <img src={img1} alt="img1" className="w-100" />
                </TabContent>
                <TabContent id="tab2" activeTab={activeTab}>
                  <img src={img2} alt="img2" className="w-100" />
                </TabContent>
                <TabContent id="tab3" activeTab={activeTab}>
                  <img src={img3} alt="img3" className="w-100" />
                </TabContent>
              </div>
            </div>
          </div>
          {/* End Hero Image */}
        </div>
      </div>
    </div>
  );
};

export default Tabs;
