import React from "react";
import icon1 from "../../assets/image/media/support/png/SupportRemote.png";
import icon2 from "../../assets/image/media/support/png/SupportResources.png";
import icon3 from "../../assets/image/media/support/png/SupportSecurity.png";
import icon4 from "../../assets/image/media/support/png/SupportVersion.png";
import icon5 from "../../assets/image/media/support/png/SupportMarket.png";
import icon6 from "../../assets/image/media/support/png/SupportProgressive.png";
import FeatureCard2 from "../../components/FreatureCard2/FreatureCard2";
const items = [
  {
    icon: icon1,
    title: "Remote management",
    content:
      "Our team continuously monitors your application and infrastructure, ensuring performance and availability remain high.",
  },
  {
    icon: icon2,
    title: "Resource planning",
    content:
      "We’ll work with you to ensure you have the resources in place to support your product as you scale up.",
  },
  {
    icon: icon3,
    title: "Security reviews",
    content:
      "We conduct regular security reviews and apply patches as needed to protect against emergent threats.",
  },
  {
    icon: icon4,
    title: "Version updates",
    content:
      "We’ll manage upgrades to your application, database, and hosting environment with minimal service disruption.",
  },
  {
    icon: icon5,
    title: "Market insights",
    content:
      "We’re plugged into the SaaS world, and we’ll keep you up to speed with how similar products are evolving.",
  },
  {
    icon: icon6,
    title: "Progressive improvement",
    content:
      "The dev process never truly ends—there’s always a new technology around the corner or a new integration to implement.",
  },
];

const Feature = () => {
  return (
    <div className="pt-24 pb-14 pb-md-18 pb-lg-24">
      <div className="container">
        {/* section title */}
        <div className="row justify-content-center">
          <div className="col-xl-9 col-lg-8 col-md-8 col-xs-8">
            <div
              className="text-center mb-7 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <h2 className="font-size-10 letter-spacing-n83">
                And here’s what’s happening behind the scenes:
              </h2>
            </div>
          </div>
        </div>
        {/* End Section title */}
        <div className="row justify-content-center">
          {items.map((item, index) => (
            <div
              className="col-xl-6 col-lg-5 col-md-6 col-sm-9 col-xs-11 mb-7 mb-lg-15"
              data-aos="fade-up"
              data-aos-duration={900}
              key={index}
            >
              <FeatureCard2 options={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Feature;
