import React from "react";
import img from "../../assets/image/media/support/png/HeroSupport.png";

const Hero = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container pt-25 pt-lg-31 pb-13 pb-lg-18">
        <div className="row align-items-center">
          {/* Hero Content */}
          <div className="col-xl-6 col-lg-6 col-md-6 col-xs-8 order-2 order-lg-1">
            <div
              className="mt-8 mt-lg-0"
              data-aos="fade-right"
              data-aos-delay={500}
            >
              <p className="font-size-4 mb-4">CLIENT-FOCUSED</p>
              <h2 className="font-size-10 mb-8">
                We're making support better for everyone
              </h2>
              <p className="font-size-6 mb-0">
                Forget about unanswered emails or long phone calls spent mostly
                on hold—our proactive support service is focused on meeting your
                needs as quickly and effectively as possible.
              </p>
              <p>
                We take responsibility for the success of your product, working
                in the background to ensure it’s stable, secure, and performing
                well—so there’s nothing for you to worry about. And we’ll advise
                on potential improvements over the product lifespan.
              </p>
            </div>
          </div>
          {/* End Hero Content */}
          {/* Hero Image */}
          <div className="col-xl-6 col-lg-6 col-md-6 col-xs-8 order-1 order-md-2">
            <div className="pt-lg-3" data-aos="flip-right" data-aos-delay={900}>
              <div className="l3-content-image-5 mt-5 mt-lg-0">
                <img className="w-100" src={img} alt="heroImg" />
              </div>
            </div>
          </div>
          {/* End Hero Image */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
